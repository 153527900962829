/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
body {
  margin: 0;
  padding: 0; }

table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle; }

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

a img {
  border: none; }

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */ }

table {
  margin: 0 0 1.5em;
  width: 100%; }

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
  width: 100%;
  height: 50vh;
  margin-bottom: 0px; }

.post, .page {
  margin: 0 0 0em; }

/*--------------------------------------------------------------
  # Clearfix
  --------------------------------------------------------------*/
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
  table-layout: fixed; }

.clearfix {
  clear: both; }

.float-r {
  float: right; }

/*--------------------------------------------------------------
  # Wrappers / Containers
  --------------------------------------------------------------*/
@media screen and (max-width: 5000px) {
  .content-wrapper-lg {
    max-width: 1000px;
    margin: auto;
    padding: 1.5rem; }
  .content-wrapper-md {
    max-width: 800px;
    margin: 0rem auto;
    padding: 1.5rem; }
  .content-wrapper-sm {
    max-width: 700px;
    margin: 0rem auto;
    padding: 1.5rem; }
  .content-wrapper-xs {
    max-width: 600px;
    margin: auto;
    padding: 1.5rem; } }

.row:before {
  content: none !important;
  display: inline-block !important; }

.row:after, .row:before {
  content: none !important;
  display: inline-block !important; }

/*--------------------------------------------------------------
# Flex Classes
--------------------------------------------------------------*/
.display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.flex-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.flex-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }

.align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-content: center;
  align-content: center; }

/*--------------------------------------------------------------
  # Grid
  --------------------------------------------------------------*/
.row-space-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }

.row-space-around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: space-around; }

.row-space-evenly {
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  -ms-flex-pack: space-evenly; }

/*--------------------------------------------------------------
# Flex Width Classes
--------------------------------------------------------------*/
.flex-10 {
  flex: 0 0 10%; }
  @media (max-width: 767px) {
    .flex-10 {
      flex: 0 0 100% !important; } }

.flex-15 {
  flex: 0 0 15%; }
  @media (max-width: 767px) {
    .flex-15 {
      flex: 0 0 100% !important; } }

.flex-20 {
  flex: 0 0 20%; }
  @media (max-width: 767px) {
    .flex-20 {
      flex: 0 0 100% !important; } }

.flex-25 {
  flex: 0 0 25%; }
  @media (max-width: 767px) {
    .flex-25 {
      flex: 0 0 100% !important; } }

.flex-30 {
  flex: 0 0 30%; }
  @media (max-width: 767px) {
    .flex-30 {
      flex: 0 0 100% !important; } }

.flex-33 {
  flex: 0 0 33%; }
  @media (max-width: 767px) {
    .flex-33 {
      flex: 0 0 100% !important; } }

.flex-35 {
  flex: 0 0 35%; }
  @media (max-width: 767px) {
    .flex-35 {
      flex: 0 0 100% !important; } }

.flex-40 {
  flex: 0 0 40%; }
  @media (max-width: 767px) {
    .flex-40 {
      flex: 0 0 100% !important; } }

.flex-45 {
  flex: 0 0 45%; }
  @media (max-width: 767px) {
    .flex-45 {
      flex: 0 0 100% !important; } }

.flex-55 {
  flex: 0 0 55%; }
  @media (max-width: 767px) {
    .flex-55 {
      flex: 0 0 100% !important; } }

.flex-50 {
  flex: 0 0 50%; }
  @media (max-width: 767px) {
    .flex-50 {
      flex: 0 0 100% !important; } }

.flex-60 {
  flex: 0 0 60%; }
  @media (max-width: 767px) {
    .flex-60 {
      flex: 0 0 100% !important; } }

.flex-65 {
  flex: 0 0 65%; }
  @media (max-width: 767px) {
    .flex-65 {
      flex: 0 0 100% !important; } }

.flex-70 {
  flex: 0 0 70%; }
  @media (max-width: 767px) {
    .flex-70 {
      flex: 0 0 100% !important; } }

.flex-75 {
  flex: 0 0 75%; }
  @media (max-width: 767px) {
    .flex-75 {
      flex: 0 0 100% !important; } }

.flex-80 {
  flex: 0 0 80%; }
  @media (max-width: 767px) {
    .flex-80 {
      flex: 0 0 100% !important; } }

.flex-90 {
  flex: 0 0 90%; }
  @media (max-width: 767px) {
    .flex-90 {
      flex: 0 0 100% !important; } }

.flex-100 {
  flex: 0 0 100%; }

/*--------------------------------------------------------------
  # Padding and Margin Classes
  --------------------------------------------------------------*/
.p2 {
  padding: 2rem; }

.pl-1 {
  padding-left: 2rem; }

.ptb-1 {
  padding: 1rem 0rem; }

.p1-tb {
  padding: 1rem 0rem; }

.p5-tb {
  padding: 5rem 0rem; }

.p3-tb {
  padding: 3rem 0rem; }

.p2-tb {
  padding: 2rem 0rem; }

.p15-rl {
  padding: 0rem 15px; }

.pr-15 {
  padding-right: 15px; }

.pl-15 {
  padding-left: 15px; }

.p2-rl {
  padding: 0rem 2rem; }

.p3-rl {
  padding: 0px 30px; }

.p2-r {
  padding: 0rem 2rem 0em 0em; }

.p2-l {
  padding: 0rem 0rem 0em 2em; }

.p3-r {
  padding: 0rem 3rem 0em 0em; }
  @media (max-width: 991px) {
    .p3-r {
      padding: 0rem; } }

.p3-l {
  padding: 0rem 0rem 0em 3em; }
  @media (max-width: 991px) {
    .p3-l {
      padding: 0rem; } }

.ptb-2 {
  padding: 2rem 0rem; }

.p2-t {
  padding-top: 2rem; }

.p2-b {
  padding-bottom: 2rem; }

.p1-tb {
  padding: 1rem 0rem; }

.p4 {
  padding: 4rem 0rem; }

.p5 {
  padding: 5rem 0rem; }

.mt-5 {
  margin-top: 5rem; }

.mt-3 {
  margin-top: 3rem; }

.mt-1 {
  margin-top: 1rem; }

.mt-2 {
  margin-top: 2rem; }

/*--------------------------------------------------------------
  # Width classes
  --------------------------------------------------------------*/
.w-50 {
  width: 50%;
  padding: 1rem;
  margin: 0 auto; }
  @media (max-width: 991px) {
    .w-50 {
      width: 100%; } }

/*--------------------------------------------------------------
  # Text Align
  --------------------------------------------------------------*/
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.btn, .btn:link, .btn:visited {
  text-decoration: none;
  transition: all .2s;
  font-size: 18px;
  padding: 1rem 3.5rem;
  font-family: Arial, Helvetica, sans-serif;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  border-radius: 0px;
  display: inline;
  text-align: center;
  cursor: pointer;
  border-radius: .5rem; }

.btn:active, .btn:focus {
  outline: none;
  text-decoration: none; }

.btn-teal:link, .btn-teal:visited {
  background: #36809a;
  color: #ffffff;
  width: fit-content;
  display: inline-block;
  border: none; }

.btn-teal:active, .btn-teal:focus, .btn-teal:hover {
  background: #0c2943;
  color: #ffffff;
  width: fit-content;
  display: inline-block; }

input[type="submit"] {
  background: #36809a !important;
  color: #ffffff !important;
  width: 100%;
  display: inline-block;
  border: none !important;
  outline: none; }
  input[type="submit"]:active, input[type="submit"]:focus, input[type="submit"]:hover {
    background: #0c2943 !important;
    color: #ffffff;
    width: 100%;
    display: inline-block; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  color: #7d7d7c;
  width: auto;
  margin: 0 !important; }

html {
  font-size: 10px; }

body {
  font-family: Arial, Helvetica, sans-serif; }

.white {
  color: #ffffff; }

.navy {
  color: #0c2943; }

.seafoam {
  color: #7ee6c7; }

.teal {
  color: #36809a; }

p a {
  text-decoration: none; }

p a:hover, a:hover {
  text-decoration: none;
  cursor: pointer; }

a:active,
a:hover,
a:focus,
a:visited {
  outline: 0;
  text-decoration: none;
  border: none;
  color: inherit; }

h1, h2, h3, h4, h5, p, span {
  letter-spacing: 0; }

h1, h2, h3, h4, h5 {
  margin-bottom: 1.5rem;
  margin-top: 0; }

h1, h1 a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.8rem;
  line-height: 3.5rem;
  font-weight: 400; }
  @media (max-width: 991px) {
    h1, h1 a {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 3.8rem;
      line-height: 3.5rem;
      font-weight: 400; } }

h2, h2 a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3rem;
  line-height: 3.4rem;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 2px; }
  @media (max-width: 991px) {
    h2, h2 a {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 3rem;
      line-height: 3.4rem;
      font-weight: 700; } }

      h2.white a {
        color:#fff;
      }

h3, h3 a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.8rem;
  line-height: 3.2rem;
  font-weight: 700; }
  @media (max-width: 991px) {
    h3, h3 a {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2.8rem;
      line-height: 3.2rem;
      font-weight: 700; } }

h3 a {
  text-decoration: none; }

h4, h4 a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-weight: 700; }
  @media (max-width: 991px) {
    h4, h4 a {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2.2rem;
      line-height: 2.2rem;
      font-weight: 700; } }

h4.border-bottom {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 700;
  border-bottom: 5px solid #64e0fb;
  padding-bottom: 1rem;
  width: 300px;
  margin: 0 auto;
  color: #0c2943; }
  @media (max-width: 991px) {
    h4.border-bottom {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 700; } }

h5, h5 a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 700; }
  @media (max-width: 991px) {
    h5, h5 a {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      line-height: 1.6rem;
      font-weight: 700; } }

span.teal-footer {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 700;
  color: #36809a; }
  @media (max-width: 991px) {
    span.teal-footer {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 700; } }

span.teal-bold {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 700;
  color: #36809a;
  margin-bottom: 0; }
  @media (max-width: 991px) {
    span.teal-bold {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 700; } }

p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 2.7rem;
  font-weight: 400;
  color: #7d7d7c; }
  @media (max-width: 991px) {
    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2rem;
      line-height: 2.7rem;
      font-weight: 400; } }

footer p, p.small {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: #7d7d7c; }
  @media (max-width: 991px) {
    footer p, p.small {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 400; } }

p.small-bold {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
  color: #0c2943; }
  @media (max-width: 991px) {
    p.small-bold {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 700; } }

ul.key {
  list-style-type: none;
  padding-inline-start: 0;
  margin: 1rem 0; }
  ul.key li {
    margin: 0.5rem 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 400;
    color: #7d7d7c; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

.material-symbols-outlined {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48; }

/*--------------------------------------------------------------
  # Forms
  --------------------------------------------------------------*/
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

form button,
form input[type="button"],
form input[type="reset"],
form input[type="submit"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  max-width: 100%;
  padding: 0.5em 2em; }

form input[type="text"], form input[type="email"], form input[type="password"] form textarea {
  margin: 1rem 0;
  padding: 1.2rem;
  border: 1px solid #7d7d7c;
  outline: none;
  width: 90%;
  box-shadow: none;
  border-radius: .5rem; }

form input[type="submit"] {
  background: #36809a;
  color: #ffffff;
  outline: none;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  line-height: 2.2rem;
  font-weight: 700;
  box-shadow: none;
  border-radius: .5rem;
  border: none;
  padding: 1.2rem; }

form input[type="submit"]:hover, form input[type="submit"]:focus {
  background: #0c2943 !important; }

label {
  margin-bottom: 1.5em;
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7rem;
  line-height: 2.2rem;
  font-weight: 700; }

input[type="text"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="url"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
input[type="number"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="range"]:-moz-placeholder,
input[type="date"]:-moz-placeholder,
input[type="month"]:-moz-placeholder,
input[type="week"]:-moz-placeholder,
input[type="time"]:-moz-placeholder,
input[type="datetime"]:-moz-placeholder,
input[type="datetime-local"]:-moz-placeholder,
input[type="color"]:-moz-placeholder,
textarea:-moz-placeholder {
  border: none;
  outline: none;
  color: #7d7d7c; }

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="range"]:-ms-input-placeholder,
input[type="date"]:-ms-input-placeholder,
input[type="month"]:-ms-input-placeholder,
input[type="week"]:-ms-input-placeholder,
input[type="time"]:-ms-input-placeholder,
input[type="datetime"]:-ms-input-placeholder,
input[type="datetime-local"]:-ms-input-placeholder,
input[type="color"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #7d7d7c;
  border: none;
  outline: none; }

input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="range"]::-webkit-input-placeholder,
input[type="date"]::-webkit-input-placeholder,
input[type="month"]::-webkit-input-placeholder,
input[type="week"]::-webkit-input-placeholder,
input[type="time"]::-webkit-input-placeholder,
input[type="datetime"]::-webkit-input-placeholder,
input[type="datetime-local"]::-webkit-input-placeholder,
input[type="color"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #7d7d7c;
  border: none;
  outline: none; }

button,
input,
select,
textarea {
  color: #7d7d7c; }

textarea {
  max-width: 100%; }

.record-keeper label {
  font-size: 17px;
  margin: 0; }

.record-keeper input[type="search"] {
  margin: 1rem 0;
  padding: 1.5rem;
  border: 1px solid #7d7d7c;
  outline: none;
  width: 50%;
  box-shadow: none;
  border-radius: .5rem; }

.record-keeper input[type="search"]:hover, .record-keeper input[type="search"]:focus {
  background: transparent; }

/*--------------------------------------------------------------
  # Creating toggle switch
  --------------------------------------------------------------*/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-bottom: 0; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #666666;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #36809a; }

input:focus + .slider {
  box-shadow: 0 0 1px #36809a; }

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px); }

/* Rounded sliders */
.slider.round {
  border-radius: 30px; }

.slider.round:before {
  border-radius: 50%; }

footer {
  background: #f3f4f6; }

header {
  background: #0c2943;
  padding: 0; }
  header img.logo {
    margin-bottom: 2rem; }

header.interior #main-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }
  header.interior #main-nav ul {
    padding-inline-start: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: auto; }
    header.interior #main-nav ul li {
      margin: 0 2rem; }
      header.interior #main-nav ul li a {
        color: #ffffff;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.377rem;
        line-height: 2.2rem;
        font-weight: 700;
        text-decoration: none; }
        @media (max-width: 991px) {
          header.interior #main-nav ul li a {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.377rem;
            line-height: 2.2rem;
            font-weight: 700; } }
      header.interior #main-nav ul li a.account img {
        vertical-align: middle; }
      header.interior #main-nav ul li a.account::after {
        content: url("../img/global/icon-down-white.svg");
        vertical-align: middle;
        margin-left: 10px; }
      header.interior #main-nav ul li a.home-btn {
        color: #0c2943;
        background: #64e0fb;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.377rem;
        line-height: 2.2rem;
        font-weight: 700;
        text-decoration: none;
        padding: 1rem 1.5rem;
        border-radius: 0.8rem; }
        @media (max-width: 991px) {
          header.interior #main-nav ul li a.home-btn {
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.377rem;
            line-height: 2.2rem;
            font-weight: 700; } }
        header.interior #main-nav ul li a.home-btn:hover, header.interior #main-nav ul li a.home-btn:focus {
          background: #36809a;
          color: #ffffff; }

header.interior #plan-lineup-nav ul {
  padding-inline-start: 0;
  list-style-type: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  header.interior #plan-lineup-nav ul li {
    margin: 0 2rem 0 0; }
    header.interior #plan-lineup-nav ul li a {
      color: #ffffff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.7rem;
      line-height: 2.2rem;
      font-weight: 700;
      text-decoration: none; }
      @media (max-width: 991px) {
        header.interior #plan-lineup-nav ul li a {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 1.7rem;
          line-height: 2.2rem;
          font-weight: 700; } }
      header.interior #plan-lineup-nav ul li a.active, header.interior #plan-lineup-nav ul li a:focus, header.interior #plan-lineup-nav ul li a:hover {
        color: #7ee6c7; }

main {
  padding: 0;
  margin: 0;
  background: #f3f4f6; }

main.white {
  padding: 0;
  margin: 0;
  background: #ffffff; }

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2rem; }

td, th {
  border-bottom: 2px solid #f3f4f6;
  text-align: left;
  padding: .8rem; }

td.center, th.center {
  text-align: center; }

th {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 700;
  color: #0c2943;
  background: #ffffff; }

td {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 400;
  color: #333333; }
  td img {
    margin: 0 .5rem 0 0;
    vertical-align: middle; }

tr:nth-child(even) {
  background-color: #fbfbfb; }

tfoot td {
  font-weight: bold; }

tfoot tr {
  border-top: 2px solid #333333; }

tfoot th {
  color: #0c2943; }

tfoot td, tfoot th {
  border: none;
  text-align: left;
  padding: .8rem; }

table.four-column td, table.four-column th {
  width: 25%; }

table.four-column td.lg, table.four-column th.lg {
  width: 35%; }

table.four-column td.md, table.four-column th.md {
  width: 25%; }

table.four-column td.sm, table.four-column th.sm {
  width: 20%; }

table.two-column td.two-thirds, table.two-column th.two-thirds {
  width: 55%; }

table.two-column td.one-third, table.two-column th.one-third {
  width: 45%; }

section.fund-selection-accordion .accordion {
  background-color: transparent;
  cursor: pointer;
  padding: 0.5rem 0;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  transition: 0.4s; }
  section.fund-selection-accordion .accordion h4 span::before {
    content: '+';
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: #0c2943;
    display: inline-block; }

section.fund-selection-accordion .active h4 span::before {
  content: '-';
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 700;
  color: #0c2943;
  display: inline-block; }

section.fund-selection-accordion .panel {
  padding: 0;
  display: none;
  background-color: transparent;
  overflow: hidden; }
  section.fund-selection-accordion .panel .fund-selection-box {
    background: #ffffff;
    padding: 2rem; }

section.sample-allocation-accordion .accordion {
  background-color: transparent;
  cursor: pointer;
  padding: 1.5rem 0 0rem 0;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  border-top: 3px solid #f3f4f6;
  border-bottom: 3px solid #f3f4f6; }
  section.sample-allocation-accordion .accordion h4::after {
    content: url("../img/global/icon-arrow-down.svg");
    float: right;
    width: 20px;
    height: 15px;
    padding-right: 5px; }

section.sample-allocation-accordion .active, section.sample-allocation-accordion .accordion:hover {
  background-color: #fff; }
  section.sample-allocation-accordion .active h4::after, section.sample-allocation-accordion .accordion:hover h4::after {
    content: url("../img/global/icon-arrow-up.svg");
    float: right;
    width: 20px;
    height: 15px;
    padding-right: 5px; }

section.sample-allocation-accordion .panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden; }
  section.sample-allocation-accordion .panel label {
    line-height: 0.5rem;
    margin-bottom: 0;
    margin-top: 0; }

section.sample-allocation-accordion .accordion.active h4::after {
  content: url("../img/global/icon-arrow-up.svg");
  float: right;
  width: 20px;
  height: 15px;
  padding-right: 5px; }

section.sample-allocation-accordion .accordion.active .panel {
  display: block; }

section.sample-allocation-accordion label {
  font-weight: 400;
  color: #0c2943; }

.border-gray-bottom {
  border-bottom: 3px solid #f3f4f6;
  padding-bottom: 1rem; }

.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 0;
  line-height: 0;
  vertical-align: middle;
  margin-right: 5px; }
  .dot--dark-green {
    background: #379343; }
  .dot--forrest-green {
    background: #52b247; }
  .dot--light-green {
    background: #c8e3b8; }
  .dot--green {
    background-color: #7ffa01; }
  .dot--lime-green {
    background-color: #00fe80; }
  .dot--blue {
    background-color: #00bcfa; }
  .dot--violet-blue {
    background-color: #3f60dd; }
  .dot--cobalt {
    background-color: #2e40c4; }
  .dot--electric-green {
    background-color: #b9e690; }
  .dot--teal {
    background-color: #449cbf; }
  .dot--pistachio {
    background-color: #d7f1b4; }
  .dot--ocean {
    background-color: #ccebda; }
  .dot--kelly-green {
    background-color: #4daf63; }
  .dot--grass {
    background-color: #6fb034; }
  .dot--med-blue {
    background-color: #3563a9; }
  .dot--navy {
    background-color: #1e2d60; }
  .dot--pale-blue {
    background-color: #85b9dc; }
  .dot--purple {
    background-color: #5e007e; }
  .dot--light-purple {
    background-color: #7f369e; }
  .dot--marroon {
    background-color: #810160; }

.dot-small {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 0;
  line-height: 0;
  vertical-align: middle;
  margin-right: 5px; }
  .dot-small--green {
    background: #58a617; }
  .dot-small--blue {
    background: #019fda; }
  .dot-small--navy {
    background: #002663; }
  .dot-small--orange {
    background: #e37222; }
  .dot-small--red {
    background: #a71930; }
  .dot-small--marigold {
    background: #f0ab00; }
  .dot-small--purple {
    background: #500778; }

.square {
  height: 10px;
  width: 10px;
  background-color: #e2e2e2;
  border-radius: 0%;
  display: inline-block;
  margin-bottom: 0;
  line-height: 0;
  vertical-align: middle;
  margin-right: 5px; }

.form-box {
  background: #ffffff;
  padding: 0;
  margin: 1rem auto;
  border-radius: 1.5rem;
  border: 1px solid #e9eae8; }
  .form-box__header {
    background-image: url("../img/01-login/sign-in-masthead-1500x470@2x.jpg");
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
  .form-box form.sign-in ul {
    list-style-type: none;
    column-count: 1;
    -moz-column-count: 2;
    /* Firefox */
    -webkit-column-count: 2;
    /* Safari and Chrome */
    padding-inline-start: 0;
    padding: 2rem; }
    .form-box form.sign-in ul li {
      padding: 0.5rem; }
      .form-box form.sign-in ul li input[type="submit"] {
        margin-top: 3.5rem;
        padding: 1rem; }
    .form-box form.sign-in ul li.no-pad {
      padding: 1rem 1rem 0rem 1rem; }

section.home-masthead {
  background: #103354;
  padding: 2.5rem; }

.icon-card {
  background: #ffffff;
  padding: 3rem;
  text-align: center;
  width: 26%;
  position: relative;
  height: 150px;
  margin-top: 10rem;
  border-radius: 1.5rem;
  border: 1px solid #e9eae8; }
  .icon-card__icon {
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    padding: 1.5rem;
    width: 100px;
    height: 100px;
    background: #cadee5;
    position: absolute;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .icon-card__icon img {
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .icon-card a {
    text-decoration: none; }
    .icon-card a h3 {
      padding-top: 7rem;
      color: #0c2943;
      text-decoration: none; }
    .icon-card a:hover, .icon-card a:focus {
      text-decoration: underline; }
  @media (max-width: 576px) {
    .icon-card {
      width: 100%; } }

section.investment-lineup-search {
  background: #ffffff;
  padding: 1rem 0;
  margin: 0; }

section.how-it-works {
  padding: 1rem 0;
  margin: 0; }

section.fund-selection-accordion {
  padding: 0;
  margin: 0;
  background: #f3f4f6; }

.graph-control {
  background: #f0f0f0;
  padding: 1rem; }
  .graph-control strong {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: #333333; }
  .graph-control input[type="text"] {
    width: 75%; }
  .graph-control input[type="submit"] {
    padding: 1rem;
    margin-top: 1rem; }

.performance-tabs-nav ul {
  margin: 0;
  padding: 5px 0px 15px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between; }
  .performance-tabs-nav ul li {
    display: inline-block;
    text-align: center; }
    .performance-tabs-nav ul li a {
      padding-bottom: 3px;
      text-decoration: none;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 1.62rem;
      line-height: 2.43rem;
      color: #666666; }
      .performance-tabs-nav ul li a:hover, .performance-tabs-nav ul li a:focus {
        color: #36809a;
        text-decoration: none;
        border-bottom: 5px solid #64e0fb; }
        .performance-tabs-nav ul li a.disabled:hover, .performance-tabs-nav ul li a.disabled:focus {
          color: #666666;
          text-decoration: none;
          border-bottom: none;
          cursor: not-allowed;
        }
      .performance-tabs-nav ul li a:visited {
        color: #666666; }
    .performance-tabs-nav ul li a.active {
      color: #36809a !important;
      text-decoration: none;
      border-bottom: 5px solid #64e0fb; }

.performance-tab {
  display: none; }

.performance-tab.open {
  display: flex;
  min-height: 400px; }

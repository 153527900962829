/* accounting for svg images in React not being a source in img tag but a svg tag of its own */
.icon-card__icon svg {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

/* preventing home page text cards from falling apart on longer icon text */
.icon-card {
    height: auto }

/* added cursor on time page legend path hover to indicate possible click action */
.key li.clickable {
    cursor: pointer;
}

/* displaying .dot in legend for S&P as horizontal line vs colored dot, overwriting style tag via !important */
.dot-small.sp {
    border-radius: 0;
    border-bottom: 2px solid #000000;
    background-color: transparent !important;
}

/* adding cursor pointer to radio buttons and labels */
input[type=radio], input[type=radio] + label {
    cursor: pointer;
}

/* attendee info */
#attendee-info {
    padding: 1em;
}

/* stretching performance, gps and time wrappers out */
.content-wrapper-lg {
    max-width: 90%;
}

/* setting submit button's width to 100% after form */
#attendee-submit-button {
    padding: 0 1em 1em 1em;
}

/* making multiple attendees form possible, stacking them vertically in one column instead of two */
.form-box form.sign-in ul {
    list-style-type: none;
    column-count: 1;
    -moz-column-count: 1;
    -webkit-column-count: 1;
}

/* button like icons */
svg {
    cursor: pointer;
}

/* buttons to add/remove recipients */
form button {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.377rem;
    line-height: 2.2rem;
    font-weight: 700;
    text-decoration: none;
    padding: 1rem 1.5rem;
    border-radius: 0.8rem;
    border: none;
    text-align: center;
    margin-right: 1em;
}

.primary {
    color: #0c2943;
    /* background: #64e0fb; */
}

.danger {
    color: #721c24ed;
    /* background: #64e0fb; */
}

#root {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
}

#mgd-account-image {
    max-width: 1000px;
}

.mgd-image-container {
    text-align: center;
}

td {
    font-size: 1.7rem;
}
.model-chart {
    position: relative;
    text-align: center;
}

#model-chart-label {
    color: #000000;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#model-chart-label p {
    margin: 0;
    margin-bottom: 0.5em;
    line-height: 1em;
}

.caption {
    color: #000;
    font-weight: 600;
    font-size: 14px;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.4 !important;
}

.footer {
    position: fixed;
    bottom: 0px;
}

.radio-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.radio-row label {
    margin-left: 1em;
}

.footer-container {
    height: 100%;
    box-sizing: border-box;
    display: grid;
}